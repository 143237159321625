import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Нийтэлсэн үнэт цаасны явцын мэдээг хэрхэн шинэчлэх вэ?",
  "description": null,
  "author": "OTC help",
  "category": "security-disclosures",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Нийтэлсэн үнэт цаасны явцын мэдээг хэрхэн шинэчлэх вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Үнэт цаасны явцын мэдээг зөвхөн тухайн үнэт цаасыг системд бүртгүүлэн үүсгэсэн андеррайтер оруулах, шинэчлэх эрхтэй.`}</li>
      <li parentName="ol">{`Үндсэн цэсний `}<strong parentName="li">{`[Security Disclosure]`}</strong>{` хэсэгт андеррайтерын өөрийн нийтэлсэн үнэт цаасны явцын мэдээний жагсаалт байх бөгөөд аль нэг мэдээг шинэчлэх тохиолдолд мэдээний баруун хэсэг дэх `}<strong parentName="li">{`[Request Update]`}</strong>{` товчийг дарж админ хүсэлт илгээнэ.`}</li>
      <li parentName="ol">{`Админ хүсэлтийг зөвшөөрсөн үед `}<strong parentName="li">{`[Update Disclosure]`}</strong>{` товч идэвхжиж явцын мэдээ шинэчлэх цонх нээгдэнэ.`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Title]`}</strong>{` хэсэгт мэдээ, мэдээллийн гарчиг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Description]`}</strong>{` хэсэгт мэдээллийн товч тайлбар оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Form type]`}</strong>{` хэсэгт `}<strong parentName="li">{`[File]`}</strong>{` буюу бэлэн файл хавсаргах эсвэл `}<strong parentName="li">{`[Form]`}</strong>{` хэсгийг дарж платформ дахь нэмэлт мэдээллийн хэсгүүдийг бөглөн `}<strong parentName="li">{`[Update]`}</strong>{` дарж явцын мэдээг шинэчлэн нийтэлнэ.`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      